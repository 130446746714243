import React, { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { AuthDetails } from '../libs/authDetails'
import Button from '../components/Button'
import { getAllUsersTripDetails, getVehicleType } from '../services/ApiServices'
import { useTranslation } from 'react-i18next'
import Loader from 'react-js-loader'
import Header from '../components/Header'
import { TripDetail, VehicleType } from '../types/userList'
import AuthChecker from '../libs/AuthChecker'
import moment from 'moment'

const UserList = () => {
  const { token } = AuthDetails()
  const { t } = useTranslation()
  const getCurrentDate = () => {
    return moment().format('YYYY-MM-DD');
  };
  const [filters, setFilters] = useState({
    date: getCurrentDate(),
    vehicleType: '',
    limit: 10,
    offset: 0
  })
  const [tripData, setTripData] = useState<TripDetail[]>([])
  console.log("bdhbs", tripData)
  const [totalTrips, setTotalTrips] = useState<number>(0)
  const [isLoad, setIsLoad] = useState<any>(false)
  console.log("tripData", isLoad)
  const fetchTripDetails = async (): Promise<{
    trip_details: TripDetail[]
    total_trips: number
  }> => {
    const { date, vehicleType, limit, offset } = filters
    const response = await getAllUsersTripDetails(
      date,
      vehicleType,
      limit,
      offset,
      token
    )
    return response
  }

  const {
    data: tripDetailsResponse,
    isLoading,
    error
  } = useQuery<{ trip_details: TripDetail[]; total_trips: number }>(
    ['tripData', filters],
    fetchTripDetails,
    {
      keepPreviousData: true
    }
  )
  const renderGarbageTypes = (trip: TripDetail) => {
    if (trip && trip.garbageType) {
      return trip.garbageType
        .replace('[', '')
        .replace(']', '')
        .split(',')
        .map((type: string) => type?.trim())
        .join(', ')
    }
    return ''
  }

  const convertToCSV = (data: TripDetail[]) => {
    const headers = ['Sno.', 'Employee No.', 'Name', 'Driver type	', "Sub driver type", "Tour Id", "Faction", 'Vehicle number', 'Start time', 'End time', "Break Start Time", "Break End Time", "Phone number", "Diesel", "Location", "Start mileage", "Final Mileage", "Postal Code"];

    const csvContent =
      'data:text/csv;charset=utf-8,' +
      headers.join(',') +
      '\n' +
      data
        ?.map((item, index) => {
          const breakStartTimes = item.break_start_time
            ? item.break_start_time.split(',').map((time) => time.trim()).join('\n')
            : '-';
          const breakEndTimes = item.break_end_time
            ? item.break_end_time.split(',').map((time) => time.trim()).join('\n')
            : '-';

          return [
            index + 1,
            item.username || '-',
            `${item.firstname || '-'} ${item.lastname || '-'}`,
            item.category || '-',
            item.sub_category || '-',
            item.trip_id || '-',
            `"${renderGarbageTypes(item) || '-'}"`,
            item.vehicleNumber || '-',
            moment(item.check_in_time).isValid() ? moment(item.check_in_time).format(' HH:mm A') : '-',
            moment(item.check_out_time).isValid() ? moment(item.check_out_time).format(' HH:mm A') : '-',
            `"${breakStartTimes || '-'}"`,
            `"${breakEndTimes || '-'}"`,
            item.phonenumber || '-',
            item.diesel_filled_up || '-',
            item.location || '-',
            item.start_mileage || '-',
            item.final_mileage || '-',
            item.postal_code || '-',
          ].join(',');
        })
        .join('\n');

    return encodeURI(csvContent);
  };

  useEffect(() => {
    if (tripDetailsResponse) {
      const { trip_details, total_trips } = tripDetailsResponse
      setTotalTrips(total_trips)
      if (filters.offset === 0) {
        setTripData(trip_details)
        setIsLoad(false)
      } else {
        setTripData((prevData) => [...prevData, ...trip_details])
        setIsLoad(false)
      }
    }
  }, [tripDetailsResponse])

  const fetchVehicleTypes = async (): Promise<VehicleType[]> => {
    const data = await getVehicleType(token)
    return data
  }

  const handleLoadMore = () => {
    setIsLoad(true)
    if (tripData?.length < totalTrips) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        offset: prevFilters.offset + prevFilters.limit
      }))

    }
  }
  const {
    data: vehicleTypes = [] } = useQuery<VehicleType[], Error>('vehicleTypes', fetchVehicleTypes)

  const handleFilterChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    key: string
  ) => {
    if (key === 'vehicleType') {
      const selectedVehicleType = e.target.value;
      setFilters({ ...filters, vehicleType: selectedVehicleType, offset: 0 });
    } else if (key === 'date') {
      const selectedDate = e.target.value;
      setFilters({ ...filters, date: selectedDate, offset: 0 });
    } else {
      setFilters({ ...filters, [key]: e.target.value, offset: 0 });
    }
  };

  const handleExportButtonClick = async () => {
    try {
      const response = await getAllUsersTripDetails(filters.date, filters.vehicleType, totalTrips, 0, token);

      if (response && response.trip_details.length > 0) {
        const csvContent = convertToCSV(response.trip_details);
        const link = document.createElement('a');
        link.setAttribute('href', csvContent);
        link.setAttribute('download', 'tripData.csv');
        document.body.appendChild(link);
        link.click();
      }
    } catch (error) {
      console.error("Error exporting trip data:", error);
    }
  };

  AuthChecker()
  return (
    <>
      <Header style={{ paddingTop: "16px", paddingBottom: "16px" }} />
      <div className="mb-3 overFlow p-3 admin">
        {/* <div className="col-md-14 scroll" > */}
        <div className="d-flex align-items-center justify-content-between flex-row mb-2 selecterInput">
          <Button
            onButtonClick={handleExportButtonClick}
            title={t('Export_Data')}

            disabled={tripData?.length === 0 ? true : false}
            style={
              tripData?.length === 0
                ? {
                  pointerEvents: 'none',
                  opacity: 0.5,
                  background: 'grey',
                  color: 'white',
                  height: '52px'
                }
                : {
                  backgroundColor: '#027a79',
                  color: 'white',
                  height: '52px'
                }
            }
          />
          <div className="d-flex align-items-center justify-content-end flex-row mb-2 clock w-100 inputselect">
            <input
              type="date"
              className="inputform bg-white"
              style={{ fontSize: '16px', padding: '11px' }}
              value={filters.date}
              onChange={(e) => handleFilterChange(e, 'date')}
            />
            <div className="custom-select inputGagbage">
              <select
                className="selectInput"
                id="serviceDropdown"
                name={t('serviceType')}
                style={{
                  padding: '11px 11px 11px 10px',
                  background: 'white'
                }}
                value={filters.vehicleType}
                onChange={(e) => handleFilterChange(e, 'vehicleType')}
                required>
                <option value="">{t('SelectType')}</option>
                {Array.isArray(vehicleTypes) &&
                  vehicleTypes.map((type) => (
                    <option
                      key={type.cost_center_id}
                      value={type.cost_center_id}>
                      {type.vehicle_type?.toUpperCase()}
                    </option>
                  ))}
              </select>
              <span className="custom-arrow"></span>
            </div>
          </div>
        </div>
        <table className="table-responsive col-md-18">
          <thead className="tableHeading">
            <tr>
              <th className="action col-1 items">{t('Sno')}</th>
              <th className="col-1 items">{t('userName')}</th>
              <th className="col-1 items">{t('First_Name')}</th>
              <th className="col-1 items">{t('Category')}</th>
              <th className="col-1 items">{t('sub_category')}</th>
              <th className="col-1 action items">{t('Tour_Id')}</th>
              <th className="col-1 providerName items">{t('garbageType')}</th>
              <th className="col-1 items">{t('VehicleNo')}</th>
              <th className="col-1 items">{t('Start_Time')}</th>
              <th className="col-1 items">{t('end_time')}</th>
              <th className="col-1 items">{t('Pause_time')}</th>
              <th className="col-1 items">{t('resume_time')}</th>
              <th className="col-1 items">{t('PhoneNo')}</th>
              <th className="col-1 items action">{t('diesel_filled_up')}</th>

              <th className="col-1 items action">{t('Location')}</th>
              <th className="col-1 items">{t('start_mileage')}</th>
              <th className="col-1 items">{t('final_mileage')}</th>
              <th className="col-1 items">{t('postal_code')}</th>

            </tr>
          </thead>
          <tbody className="tableBody">
            {isLoading ? (
              <tr>
                <td colSpan={18} className="text-white">
                  <Loader
                    type="bubble-top"
                    bgColor={'#027a79'}
                    color={'#027a79'}
                    size={50}
                  />
                </td>
              </tr>
            ) : error ? (
              <tr>
                <td colSpan={18} className="text-white">
                  {t('errorLoadingData')}
                </td>
              </tr>
            ) : Array.isArray(tripData) && tripData?.length > 0 ? (
              tripData.map((trip, index) => (
                <tr key={index}>
                  <td className="action col-1 items">{index + 1}</td>
                  <td className="col-1 action items">{trip.username || '-'}</td>
                  <td className="col-1 items">{trip.firstname && trip.lastname ? `${trip.firstname} ${trip.lastname}` : '-'}</td>
                  <td className="col-1 items">{trip.category || '-'}</td>
                  <td className="col-1 items">{trip.sub_category || '-'}</td>
                  <td className="col-1 action items">{trip.trip_id || '-'}</td>
                  <td className="col-1 items">{renderGarbageTypes(trip) || '-'}</td>
                  <td className="col-1 items">{trip.vehicleNumber || '-'}</td>
                  <td className="col-1 items">
                    {trip.check_in_time ? moment(trip.check_in_time).format(" HH:mm A") : '-'}
                  </td>
                  <td className="col-1 items">
                    {trip.check_out_time ? moment(trip.check_out_time).format(" HH:mm A") : '-'}
                  </td>
                  <td className="col-1 items">
                    {trip.break_start_time?.split(',').map((time, index) => (
                      <span key={index}>
                        {time || '-'}
                        {index < trip.break_start_time?.split(',').length - 1 && <br />}
                      </span>
                    )) || '-'}
                  </td>
                  <td className="col-1 items">
                    {trip.break_end_time?.split(',').map((time, index) => (
                      <span key={index}>
                        {time || '-'}
                        {index < trip.break_end_time?.split(',').length - 1 && <br />}
                      </span>
                    )) || '-'}
                  </td>
                  <td className="col-1 items">{trip.phonenumber || '-'}</td>
                  <td className="col-1 items action">{trip.diesel_filled_up || '-'}</td>
                  <td className="col-1 items action">{trip.location || '-'}</td>
                  <td className="col-1 items">{trip.start_mileage || '-'}</td>
                  <td className="col-1 items">{trip.final_mileage || '-'}</td>
                  <td className="col-1 items">{trip.postal_code || '-'}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={18} className="text-white">
                  {t('noData')}
                </td>
              </tr>
            )} {isLoad ? (
              <tr>
                <td colSpan={18} className="text-white">
                  <Loader
                    type="bubble-top"
                    bgColor={'#027a79'}
                    color={'#027a79'}
                    size={50}
                  />
                </td>
              </tr>) : null}
          </tbody>
        </table>
        {!isLoad && tripData?.length < totalTrips && (
          <div className="text-white mt-3 d-flex align-items-center justify-content-center">
            <Button
              title={t('loadMore')}
              onButtonClick={handleLoadMore}
              backgroundColor="#027a79"
              color="white"
            />
          </div>
        )}
      </div>
      {/* </div> */}
    </>
  )
}

export default UserList
