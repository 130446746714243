import { faChevronCircleLeft, faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { AuthDetails } from '../libs/authDetails';
import { apiGetLocationList, getUserCarDetails, getVehicleNumber } from '../services/ApiServices';

interface FormData {
  carId: string;
  carNumber: string;
  location: string;
  postal_code: string;
}

interface DriverDetailProps {
  onNext: (data: any) => void;
  onPrevious: () => void;
  initialFormData: any;
  data: any;
  isLocation: boolean;
}

const DriverDetail: React.FC<DriverDetailProps> = ({
  onNext,
  onPrevious,
  initialFormData,
  isLocation,
}) => {
  const { t } = useTranslation();
  const { token, is_admin, userId: authUserId } = AuthDetails();
  const data11 = JSON.parse(localStorage.getItem('tripAndVehicleTypeData') || '{}');
  const UserId = is_admin === 1 ? data11.user_id : authUserId;

  const fetchVehicleNumber = async () => {
    try {
      const data = await getVehicleNumber(token);
      return data;
    } catch (error) {
      console.error('Error fetching vehicle types:', error);
      return [];
    }
  };

  const fetchUserCarNumber = async () => {
    try {
      const data = await getUserCarDetails(UserId, token);
      return data;
    } catch (error) {
      console.error('Error fetching vehicle types:', error);
      return [];
    }
  };

  const fetchLocationList = async () => {
    try {
      const data = await apiGetLocationList(token);
      return data;
    } catch (error) {
      console.error('Error fetching Location:', error);
    }
  };

  const [validationErrors, setValidationErrors] = useState({
    carNumber: '',
    carId: '',
    cost_center_id: '',
    location: '',
  });

  const { data: userCarNumber } = useQuery('userCarNumber', fetchUserCarNumber);
  const { data: locationList } = useQuery('locationList', fetchLocationList);

  const { data: vehicleNumber = [] } = useQuery('vehicleTypes', fetchVehicleNumber, {
    onSuccess: (data) => {
      if (userCarNumber) {
        const matchedVehicle = data?.find(
          (vehicle: { carNumber: string }) => vehicle.carNumber === userCarNumber.carNumber
        );
        if (matchedVehicle) {
          setFormData((prevData: FormData) => ({
            ...prevData,
            carNumber: matchedVehicle.carNumber,
          }));
        }
      }
    },
  });

  const {
    carNumber: initialCarNumber,
    carId: initialCarId,
    location: initialLocation,
    postal_code: initialPostal,
  } = initialFormData || {};

  const [formData, setFormData] = useState(() => {
    const storedFormData = localStorage.getItem('driverDetailFormData');
    return storedFormData
      ? JSON.parse(storedFormData)
      : {
        carNumber: initialCarNumber || '',
        carId: initialCarId || '',
        location: initialLocation || '',
        postal_code: initialPostal || '',
      };
  });


  useEffect(() => {
    localStorage.setItem('driverDetailFormData', JSON.stringify(formData));
  }, [formData]);

  const handleSubmit = (e?: any) => {
    if (e) {
      e.preventDefault();
    }

    if (formData && typeof formData.location === 'string') {
      const locationError = formData.location.trim() === '' ? t('Location_required') : '';
      const carError = formData.carNumber?.trim() === '' ? t('Vehicle_N_required') : '';

      setValidationErrors({
        ...validationErrors,
        location: locationError,
        carNumber: carError,
      });

      if (carError === '' && (!isLocation || locationError === '')) {
        const finalFormData = { ...formData };
        onNext(finalFormData);
      }
    }
  };

  const handleChangeVehicle = (e: any, field: string) => {
    setFormData({ ...formData, [field]: e.target.value });
    setValidationErrors({ ...validationErrors, [field]: '' });
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    field: keyof FormData
  ) => {
    const [postal_code, ...locationParts] = e.target.value.split(' ');
    setFormData({
      ...formData,
      location: locationParts.join(' '),
      postal_code,
    });

    setValidationErrors({
      ...validationErrors,
      [field]: '',
    });
  };

  return (
    <div className="d-flex align-items-center justify-content-center m-auto height">
      <form onSubmit={handleSubmit} className="main-container">
        <div className="userHeading">
          <h2 className="textcolor">
            {isLocation ? t("Please_location") : t('Vehicle_please')}
          </h2>
        </div>
        <div className="p-3">
          {isLocation ? (
            <div>
              <h6 className="mt-3 textFont">{t('Location_Subtown')}</h6>
              <select
                className="inputform"
                id="serviceDropdown"
                name="serviceType"
                style={{ width: '100%', backgroundColor: "white" }}
                value={`${formData.postal_code} ${formData.location}`}
                onChange={(e) => handleChange(e, 'location')}
                required
              >
                <option value="">{t('Location')}</option>
                {Array.isArray(locationList) &&
                  locationList.map((list: any) => (
                    <option key={list.location_id} value={`${list.postal_code} ${list.location}`}>
                      {list.postal_code} {list.location}
                    </option>
                  ))}
              </select>
              <p className='mt-1 font-weight-bold mb-2'>{t("Note")}</p>
              {validationErrors.location && (
                <span className="error-message">{validationErrors.location}</span>
              )}
            </div>
          ) : (
            <div className="d-flex align-items-center justify-content-center flex-row gap-3">
              <div className="w-100 ">
                <h6 className="textFont">{t('VehicleNo')}</h6>
                <div className="custom-select" style={{ height: '45px' }}>
                  <select
                    className="inputform"
                    id="serviceDropdown"
                    name="serviceType"
                    style={{ width: '100%', backgroundColor: "white" }}
                    value={formData.carNumber}
                    onChange={(e) => handleChangeVehicle(e, 'carNumber')}
                    required
                  >
                    <option value="">{t('VehicleNo')}</option>
                    {Array.isArray(vehicleNumber) &&
                      vehicleNumber.map((vehicle: any) => (
                        <option key={vehicle.carId} value={vehicle.carNumber}>
                          {vehicle.carNumber}
                        </option>
                      ))}
                  </select>
                  {validationErrors.carNumber && (
                    <span className="error-message">{validationErrors.carNumber}</span>
                  )}
                  {validationErrors.cost_center_id && (
                    <span className="error-message mb-2">
                      {validationErrors.cost_center_id}
                    </span>
                  )}
                  <span className="custom-arrow"></span>
                </div>
              </div>
            </div>
          )}
         
          <div
            className="d-flex align-items-center justify-content-center  mb-3 mt-5 clock" style={{ gap: 50 }}>
            <div className="d-flex justify-content-center flex-row  align-items-center gap-2" >
              <h6 className="" onClick={() => onPrevious()} style={{ cursor: 'pointer', color: '#027a79', fontSize: '20px' }} >
                {t("back")}
              </h6>
              <FontAwesomeIcon
                icon={faChevronCircleLeft}
                color="#027a79"
                className=" backIcon"
                onClick={() => onPrevious()}
              />

            </div>
            <div className="d-flex justify-content-center flex-row  align-items-center gap-2" >
              <FontAwesomeIcon
                icon={faChevronCircleRight}
                color="#027a79"
                className="backIcon"
                onClick={handleSubmit}
              />
              <h6 className="" onClick={handleSubmit} style={{ cursor: 'pointer', color: '#027a79', fontSize: '20px' }} >
                {t("further")}
              </h6>
            </div>

          </div>
        </div>
      </form>
    </div>
  );
};

export default DriverDetail;
