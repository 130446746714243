import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from './Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleLeft, faChevronCircleRight, faTimes } from '@fortawesome/free-solid-svg-icons';

interface PauseTime {
  start_time: string;
  end_time: string;
}


const StepPauseTimes: any = ({
  breaks,
  handlePauseTimeChange,
  handleEndTimeChange,
  handleRemoveBreak,
  addBreak,
  onNext,
  onPrevious,
  errorDumped,
  handleDumpChange,
  dumped,
  isDumped,
  totalSelected,
  endTimeErrors,
  startTimeErrors
}: {
  breaks: PauseTime[];
  handlePauseTimeChange: (index: number, value: string, field: 'start_time' | 'end_time') => void;
  handleEndTimeChange: (index: number, value: string, field: 'start_time' | 'end_time') => void;
  handleRemoveBreak: (index: number) => void;
  addBreak: () => void;
  onNext: () => void;
  onPrevious: () => void;
  errorDumped?: string;
  handleDumpChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  dumped?: string;
  isDumped: boolean
  totalSelected?: any
  endTimeErrors?: any,
  startTimeErrors?: any

}) => {
  const { t } = useTranslation();
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (onNext) {
        onNext();
      }
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };
  const selectDriver = localStorage.getItem('selectedDriver')
  return (
    <div className="d-flex align-items-center justify-content-center m-auto height">
      <form className="main-container" onSubmit={handleSubmit}>
        <div className="userHeading" style={{ background: "#EC5800" }}>
          <h2 className="textcolor">{isDumped ? t("dumped_hed") : `${t("pause1")}`}</h2>
        </div>
        <div className="d-flex flex-column p-3">
          {isDumped ? (
            <div className="d-flex flex-row  mt-1 mb-2 flex-column">

              <input
                type="text"
                placeholder={t('Dump_count')}
                className="inputform mt-3"
                value={dumped}
                onChange={handleDumpChange}
                onKeyDown={handleKeyPress}
              />

              {errorDumped && (
                <span className="error-message">{errorDumped}</span>
              )}
              <p className='mt-1 font-weight-bold mb-3'>{t("Dump_Note")}<span style={{ fontWeight: '900' }}>0.</span></p>
            </div>
          ) : (

            <>
              <h4 className=" text-left mb-3" style={{ fontSize: '16px' }}>
                {t('Breaks')}:
              </h4>
              <div className="d-flex align-items-center justify-content-center pauseInput w-100 mw-100 clock mb-3 flex-column">
                <div className='w-100 ' style={{ maxHeight: '160px', overflowY: breaks.length > 3 ? "scroll" : "auto" , marginBottom:"4px" }}>
                  {breaks.map((pauseTime, index) => (
                    <div className="d-flex flex-row clock" style={{ width: '100%' }} key={index}>
                      <div className='d-flex flex-column  w-100'>
                        <div className="w-100" style={{ height: '75px' }}>
                          <h6 className="col-12 inputHeading">{t('PauseFrom')}</h6>
                          <input
                            type="time"
                            className="inputform w-100"
                            style={{ background: 'white' }}
                            value={pauseTime?.start_time || ''}
                            onChange={(e) => handlePauseTimeChange(index, e.target.value, 'start_time')}
                          />
                        </div>
                        {startTimeErrors[index] && <span className="error-message">{startTimeErrors[index]}</span>}
                      </div>
                      <div className='d-flex flex-column  w-100'>
                        <div className="w-100" style={{ height: '75px' }}>
                          <h6 className=" inputHeading">{t('PauseTo')}</h6>
                          <input
                            type="time"
                            className="inputform w-100"
                            style={{ background: 'white' }}
                            value={pauseTime?.end_time || ''}
                            onChange={(e) => handleEndTimeChange(index, e.target.value, 'end_time')}
                          />
                        </div>
                        {endTimeErrors[index] && <span className="error-message">{endTimeErrors[index]}</span>}

                      </div>
                      <div className="d-flex align-items-center mt-2" style={{ height: '70px' }}>
                        <FontAwesomeIcon
                          icon={faTimes}
                          className="iconCheck"
                          onClick={() => handleRemoveBreak(index)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <Button onButtonClick={addBreak} title={`${t("pause")} ${"#"}${totalSelected}`} backgroundColor="#EC5800" color="white" />

              </div>
              {selectDriver === 'Driver' ? (
                <p className='mt-1 font-weight-bold mb-2'>
                  {t("Paused_Time")}
                  <span style={{ fontWeight: '900', textDecoration: 'underline' }}>
                    {t("Paused_Time1")}
                  </span>
                  {t("Paused_Time11")}
                </p>
              ) : selectDriver === 'Loader' ? (
                <p className='mt-1 font-weight-bold mb-2'>
                  {t("Paused_Time")}
                  <span style={{ fontWeight: '900', textDecoration: 'underline' }}>
                    {t("Paused_Time1")}
                  </span>
                  {t("Paused_Time1L")}
                </p>
              ) : null}

            </>
          )}

          <div
            className="d-flex align-items-center justify-content-center  mb-2 clock" style={{ gap: 50 }}>
            {onPrevious && (<div className="d-flex justify-content-center flex-row  align-items-center gap-1" >
              <h6 className="" onClick={onPrevious} style={{ cursor: 'pointer', color: '#EC5800', fontSize: '20px' }} >
                {t("back")}
              </h6>
              <FontAwesomeIcon
                icon={faChevronCircleLeft}
                color="#EC5800"
                className=" backIcon"
                onClick={onPrevious}
              />
            </div>
            )}
            <div className="d-flex justify-content-center flex-row  align-items-center gap-1" >
              <FontAwesomeIcon
                icon={faChevronCircleRight}
                color="#EC5800"
                className="backIcon"
                onClick={onNext}
              />
              <h6 className="" onClick={onNext} style={{ cursor: 'pointer', color: '#EC5800', fontSize: '20px' }} >
                {t("further")}
              </h6>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default StepPauseTimes;
