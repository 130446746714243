import {
  faBars,
  faEdit,
  faInfo,
  faTrashAlt,
  faUserCircle
} from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import clean from '../assets/imagesPng/clean.png'
import hand from '../assets/imagesPng/hand.png'
import pickup from '../assets/imagesPng/pickup.png'
import Button from '../components/Button'
import Calendar from '../components/Calender'
import DeleteAlert from '../components/DeleteAlert'
import Dropdown from '../components/Dropdown'
import Sidebar from '../components/SideBar'
import Spinner from '../components/Spinner'
import Clock from '../components/clock'
import AuthChecker from '../libs/AuthChecker'
import { AuthDetails } from '../libs/authDetails'
import useFileUpload from '../hooks/useFileUpload'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { QueryClient, useMutation, useQuery } from 'react-query'

import {
  apiDeleteUserTrip,
  apiUserTripDetails,
  endPoints,
  getUserProfile,
  resumeTripStatus,
  updateTripStatus
} from '../services/ApiServices'
import Modal from '../components/Modal'
import { TripDataState } from '../types/userList'
import moment from 'moment'

const DashBoard = () => {
  const queryClient = new QueryClient()
  const [tripDataState, setTripData] = useState<TripDataState[]>([])
  const navigate = useNavigate()
  const [selectedDate, setSelectedDate] = useState(() => {
    const savedDate = localStorage.getItem('selectedDate')
    return savedDate ? new Date(savedDate) : new Date()
  })
  const [dumpNumber, setDumpNumber] = useState<string>('')
  const [isTourEnd, setIsTourEnded] = useState(false)
  const [fuel, setFuel] = useState<string>('')
  const [perKm, setPerKm] = useState<string>('')
  const [PassengerName, setPassengerName] = useState<string>('')
  const [startTime, setStartTime] = useState<string | null>('')
  const [isDelete, setIsDelete] = useState(false)
  const [userIdToDelete, setUserIdToDelete] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false)
  const [loader, setLoader] = useState(false)
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const [pageSize, setPageSize] = useState(4)
  const [pageNumber] = useState(1)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const { t } = useTranslation()
  const { token, is_admin, userId, username } = AuthDetails()
  const [totalTrips, setTotalTrips] = useState<number>(0)
  const [showWarning, setShowWarning] = useState(false)
  const [showWarningStop, setShowWarningStop] = useState(false)
  const [showStop, setShowStop] = useState(false)
  const [showAnotherWarning, setShowAnotherWarning] = useState(false)
  const [isMileage, setIsMileage] = useState('')
  const [tripIdEnd, setTripIdEnd] = useState<string>('');
  const [vechileType, setVechileType] = useState('')
  const [isPaused, setIsPaused] = useState<string>(() => {
    return localStorage.getItem('paused') || '';
  });
  const [pauseTime, setPauseTime] = useState<string | null>('')
  console.log("pauseTime1111", pauseTime)
  const { UploadFile } = useFileUpload()
  const dropdownRef = useRef<HTMLDivElement>(null)
  const [userProfile, setUserProfile] = useState(null)

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await getUserProfile(userId, token)
        setUserProfile(response.profile_picture)
      } catch (error) {
        console.error('Error fetching user profile:', error)
      }
    }
    fetchUserProfile()
  }, [])

  useEffect(() => {
    localStorage.setItem('selectedDate', selectedDate.toISOString())
  }, [selectedDate])
  const {
    refetch
  } = useQuery(
    ['tripData', selectedDate, pageSize, pageNumber],
    () => fetchTripData(selectedDate, pageSize, pageNumber),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false
    }
  )

  const handleBackClick = () => {
    localStorage.removeItem('startandPauseData')
    localStorage.removeItem('selectedUser')
    localStorage.removeItem('errorMessageInput');
    localStorage.removeItem('errorMessageInput1');
    localStorage.removeItem('errorMessageInput2');
    localStorage.removeItem('currentTimeData')
    localStorage.removeItem('OptionalPassenger')
    localStorage.removeItem('driverDetailFormData')
    localStorage.removeItem('tripAndVehicleTypeData')
    localStorage.removeItem('licenseFormData')
    localStorage.removeItem('garbageFormData')
    localStorage.removeItem('signatureDataURL')
    localStorage.removeItem('currentStep')
    localStorage.removeItem(`pauseTime`);
    // localStorage.removeItem('pauseTimes')
    localStorage.removeItem('co_driver')
    localStorage.removeItem('isdriver')
    localStorage.removeItem('isCheckboxChecked')
    localStorage.removeItem("isSelectedDriver")
    localStorage.removeItem("signatureImage")
    localStorage.removeItem('mileageData')
    localStorage.removeItem('fqnCardDetails')
    localStorage.removeItem('driverCardData')
    localStorage.removeItem('selectedDriver')
  }

  useEffect(() => {
    refetch()
    handleBackClick()
  }, [selectedDate])

  const isAdmin = is_admin === 1

  useEffect(() => {
    if (isDelete || isTourEnd || showAnotherWarning) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isDelete, isTourEnd, showAnotherWarning])

  AuthChecker()
  const formatDate = (date: Date): string => {
    return moment(date).format('YYYY-MM-DD');
  };
  const fetchTripData = async (
    date: Date,
    pageSize: number,
    pageNumber: number
  ) => {
    try {
      if (!isLoadingMore) {
        setIsLoading(true)
      }
      const start_time = formatDate(date)
      const response = await apiUserTripDetails(
        start_time,
        pageSize,
        pageNumber,
        userId,
        token
      )
      const { trip_details, total_trips } = response
      if (!trip_details || !Array.isArray(trip_details)) {
        console.error('Invalid response:', trip_details)
        return
      }

      const newTripData = trip_details.filter((trip) => {
        return !tripDataState.some(
          (existingTrip) => existingTrip.trip_id === trip.trip_id
        )
      })

      await setTripData((prevTripData) => [...prevTripData, ...newTripData])
      setTotalTrips(total_trips)
    } catch (error) {
      console.error('Error fetching trip data:', error)
    } finally {
      setIsLoading(false)
      setIsLoadingMore(false)
    }
  }

  const handleDateChange = (date: React.SetStateAction<Date>) => {
    setSelectedDate(date)
    setTripData([])
  }
  const formatTime = (date: Date) => {
    return date.toTimeString().split(' ')[0]
  }
  const isATripStarted = useMemo(() => {

    return tripDataState?.some(
      (trip: TripDataState) =>
        trip.status === 1 || trip.status === 1 || trip.status === 2
    )
  }, [tripDataState])

  const handleStartButtonClick = async (tripId: number, trip: TripDataState) => {

    const startDate = trip.check_in_time;
    const currentDateTime = moment();


    const parsedStartDate = moment(startDate);
    const currentDate = currentDateTime.format('YYYY-MM-DD');
    const formattedStartDate = parsedStartDate.format('YYYY-MM-DD');
    const currentHour = currentDateTime.hour();
    const currentMinutes = currentDateTime.minute();

    if (currentDate !== formattedStartDate) {
      setShowStop(true)
      return
    }
    if (currentHour < 7 || (currentHour === 7 && currentMinutes < 0)) {
      setShowWarningStop(true);
      return;
    }
    try {
      const currentDateTimeAsDate = currentDateTime.toDate();
         localStorage.setItem(`startTime${tripId}`,formatTime(currentDateTimeAsDate))

      setStartTime(formatTime(currentDateTimeAsDate));      
      await handleStartMutation.mutateAsync({
        tripId,
        status: 1,
        startTime: formatTime(currentDateTimeAsDate),
        token
      })
    } catch (error) {
      console.error('Error starting trip:', error)
    }
  }
  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     tripDataState.forEach(trip => {
  //       if (trip.status === 0) {
  //         handleStartButtonClick(trip.trip_id, trip);
  //       }
  //     });
  //   }, 10000);

  //   return () => clearInterval(intervalId);
  // }, [tripDataState]);
  useEffect(() => {
    const checkTrips = () => {
      const now = moment();

      tripDataState.forEach(trip => {
        const startMoment = moment(moment(trip.check_in_time).format("HH:mm:ss"), 'HH:mm:ss');

        if (trip.status === 0 && now.isSameOrAfter(startMoment)) {
          handleStartButtonClick(trip.trip_id, trip);
        }
      });
    };
    checkTrips();
    const intervalId = setInterval(checkTrips, 10000);

    return () => clearInterval(intervalId);
  }, [tripDataState]);


  const [errorName, setErrorName] = useState('')
  const [errorMileage, setErrorMileage] = useState('')
  const [errorDumped, setErrorDumped] = useState('')
  const [errorFuel, setErrorFuel] = useState('')
  const [errorPerKm, setErrorPerKm] = useState('')

  const [isShowModal, setIsShowModal] = useState(false)
  const handleMilageChange = (e: { target: { value: string } }) => {
    const { value } = e.target
    setIsMileage(value)

    if (!value) {
      setErrorMileage('Starting mileage is required.')
    } else if (!/^\d*$/.test(value)) {
      setErrorMileage('only numbers allowed')
    } else {
      setErrorMileage('')
    }
  }

  const handleNameChange = (e: { target: { value: string } }) => {
    const { value } = e.target
    setPassengerName(value)
    if (!/^[A-Za-z]+$/.test(value)) {
      setErrorName(t('alphabets_allowed'))
    } else {
      setErrorName('')
    }
  }
  const handleChangeDumpNumber = (e: { target: { value: string } }) => {
    const { value } = e.target
    setDumpNumber(value)
    if (!/^[0-9]+$/.test(value)) {
      setErrorDumped(t('Only_no_allowed'))
    } else {
      setErrorDumped('')
    }
  }
  const handleChangePerKm = (e: { target: { value: string } }) => {
    const { value } = e.target
    setPerKm(value)
    if (!/^[0-9]+$/.test(value)) {
      setErrorPerKm(t('Only_no_allowed'))
    } else {
      setErrorPerKm('')
    }
  }

  const handleChangeFuelQuantity = (e: { target: { value: string } }) => {
    const { value } = e.target
    setFuel(value)
    if (!/^[0-9]+$/.test(value)) {
      setErrorFuel(t('Only_no_allowed'))
    } else {
      setErrorFuel('')
    }
  }
  const handleStopButtonClick = async (tripId: number, trip: TripDataState) => {
    const formatStartTime = trip.startTime;
    const timepause = localStorage.getItem(`startTime${tripId}`)
    console.log("formatStartTime",formatStartTime);
console.log("+++++++++++++++++++++",tripId)
if (timepause !== null) {
    const currentDateTime = new Date();
    const formattedDateTime = formatTime(currentDateTime);
    localStorage.setItem(`pauseTime-${tripId}`, formattedDateTime);
    console.log("currentDateTime");
    console.log("formattedDateTime");
      setIsPaused(tripId.toString());
  setPauseTime(formattedDateTime);

console.log("timepause",timepause)
      try {
        await handleStartMutation.mutateAsync({
          tripId,
          status: 2,
          startTime: timepause,
          token
        });
      } catch (error) {
        console.error('Error pausing trip:', error);
      }

      setTripData((prevTripData) =>
        prevTripData.map((trip) =>
          trip.trip_id === tripId
            ? {
              ...trip,
              status: 2,
              startTime: formatStartTime,
              formattedDateTime: formattedDateTime,
            }
            : trip
        )
      );
      console.log(`Trip ${tripId} paused at ${formattedDateTime}`);
    }
  };


  useEffect(() => {
    if (!userId) {
      navigate('/')
    }
  }, [])
  const [loading, setLoading] = useState(false)
  const [pauseFrom, setPauseFrom] = useState('');
  const [pauseTo, setPauseTo] = useState('');
 
  interface PauseTime {
    start_time: string;
    end_time: string;
  }

  const [breaks, setBreaks] = useState<PauseTime[]>([
    { start_time: '', end_time: '' }
  ]);

  const handlePauseTimeChange = (index: number, value: string, field: keyof PauseTime) => {
    const newBreaks = [...breaks];
    newBreaks[index][field] = value;
    setBreaks(newBreaks);
  };

  const handleEndTimeChange = (index: number, value: string, field: keyof PauseTime) => {
    const newBreaks = [...breaks];
    newBreaks[index][field] = value;
    setBreaks(newBreaks);
  };

  const handleRemoveBreak = (index: any) => {
    const newBreaks = [...breaks];
    newBreaks.splice(index, 1);
    setBreaks(newBreaks);
  };

  const addBreak = () => {
    setBreaks([...breaks, { start_time: '', end_time: '' }]);
  };
  const handleEndButtonClick = async (
    tripIdEnd: any,
    vehicleType: string
  ) => {
    const tripIdNumber = tripIdEnd;
    if (startTime !== null) {
      setLoading(true);
      const currentDateTime = new Date();
      const formattedDateTime = moment(currentDateTime).format("YYYY-MM-DD HH:mm:ss");
      let uploadFile = selectedImage as unknown as Blob | File;
      if (typeof selectedImage === 'string') {
        const response = await fetch(selectedImage);
        const blob = await response.blob();
        uploadFile = blob;
      }


      const downloadURL: string = await UploadFile(uploadFile, 'selectedImage' + username) as string;

      try {

        const response = await endPoints(
          tripIdNumber,
          selectedImage ? '1' : '0',
          dumpNumber,
          isMileage,
          '3',
          fuel,
          downloadURL,
          (vehicleType === 'Yellow bag garbage truck' ||
            vehicleType === 'Organic waste garbage truck' ||
            vehicleType === 'Paper, cardboard, cardboard garbage trucks' ||
            vehicleType === 'Emptyer')
            ? PassengerName
            : '',
          fuel,
          JSON.stringify(breaks),
          formattedDateTime,
          token
        );

        if (response && response.isSuccessful) {

          setTripData(prevTripData =>
            prevTripData.map(trip =>
              trip.trip_id === tripIdNumber
                ? {
                  ...trip,
                  status: 3,
                  startTime: startTime,
                  formattedDateTime: formattedDateTime
                }
                : trip
            )
          );


          setIsLoading(false);
          setLoading(false);
          setIsTourEnded(false);
          localStorage.removeItem('endedTripData');
          setFuel('');
          setPassengerName('');
          setDumpNumber('');
          setIsMileage('');
          setTripIdEnd('');
          setPerKm('');
          setPauseFrom("");
          setIsPaused('');
          setPauseTo('');
          setSelectedImage('');
          localStorage.setItem('endedTripData', JSON.stringify(response.data));
          setIsShowModal(true);
          refetch()
        } else {
          console.error('Error ending tour');
          setIsLoading(false);
          setLoading(false);
        }
      } catch (error) {
        console.error('Error ending tour', error);
        setIsLoading(false);
        setLoading(false);
      }
    }
  };
  const handleNO = () => {
    setIsShowModal(false)
    refetch()
    window.location.reload();
    setIsTourEnded(false)
  }
  const handleYes = () => {
    setIsShowModal(false)
    navigate('/form')
  }
  const handleCloseTourModal = () => {
    setIsTourEnded(false)
    localStorage.removeItem('endedTripData')
    setFuel('')
    setPassengerName('')
    setErrorPerKm("")
    setDumpNumber('')
    setIsMileage('')
    setTripIdEnd('')
    setIsPaused('')
    setPerKm("")
    setPauseFrom('')
    setPauseTo('')
    setSelectedImage('')
    setBreaks([])
  }


  const handleLoadMore = () => {
    setLoader(true)
    if (tripDataState?.length < totalTrips) {
      setPageSize((prevPageNumber) => prevPageNumber + 4)
      setIsLoadingMore(true)
      setLoader(false)
    }
  }
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsDropdownOpen(false)
    }
  }

  const deleteUserMutation = useMutation(
    ({ trip_id, token }: { trip_id: number; token: string }) =>
      apiDeleteUserTrip(trip_id, token),
    {
      onSuccess: async () => {
        try {
          setTotalTrips((prevTotalTrips) => prevTotalTrips - 1)
          setTripData((prevTripData) =>
            prevTripData.filter((trip) => trip.trip_id !== userIdToDelete)
          )
          queryClient.invalidateQueries('users')
          setIsDelete(false)
        } catch (error) {
          console.error('Error deleting user:', error)
        }
      },
      onError: (error) => {
        console.error('Error deleting user:', error)
      }
    }
  )

  const handleStartMutation = useMutation(
    ({
      tripId,
      status,
      startTime,
      token
    }: {
      tripId: number
      status: number
      startTime: any
      token: string
    }) => updateTripStatus(tripId, status, startTime, token),
    {
      onSuccess: (_, variables) => {
        setTripData((prevTripData) =>
          prevTripData.map((trip) =>
            trip.trip_id === variables.tripId
              ? {
                ...trip,
                startTime: variables.startTime,
                status: variables.status
              }
              : trip
          )
        )
      },
      onError: (error) => {
        console.error('Error deleting user:', error)
      }
    }
  )

const handleResumeButtonClick = async (tripId: number) => {
      console.log("mmmmmm",tripId )

  try {
    const currentDateTime = new Date();
    const resumeTime = formatTime(currentDateTime);
    console.log("resumeTime", resumeTime)
   localStorage.setItem(`startTime${tripId}`,resumeTime)
    const storedPauseTime = localStorage.getItem(`pauseTime-${tripId}`);
console.log("storedPauseTime",storedPauseTime);
    if (storedPauseTime) {
      console.log("mmmmmm", pauseTime)
      const response = await resumeTripStatus(
        storedPauseTime,
        resumeTime,
        tripId,
        1,
        token
      );
      if (response && response.isSuccessful) {
        setTripData(prevTripData =>
          prevTripData.map(trip =>
            trip.trip_id === tripId
              ? {
                ...trip,
                status: 1,
              }
              : trip
          )
        );
      }
    } else {
      console.error('Pause time not set!');
    }
  } catch (error) {
    console.error('Error resuming trip:', error);
  }
};



  const handleDeleteUser = (trip_id: number) => {
    setUserIdToDelete(trip_id)
    setIsDelete(true)
  }

  const handleConfirmDelete = async () => {
    try {
      await deleteUserMutation.mutateAsync({ trip_id: userIdToDelete, token })
      setIsDelete(false)
    } catch (error) {
      console.error('Error deleting user:', error)
    }
  }

  const backgroundImages = [clean, hand, pickup]
  const garbageTypeColors = {
    'Bulky Waste': 'green',
    ' scrap matal': 'blue',
    'Green Waste': 'darkgreen',
    'yellow bags': 'yellow'
  }
  const [selectedImage, setSelectedImage] = useState('')

  const handleImageSelected = (imageURI: string) => {
    setSelectedImage(imageURI)
  }
  return (
    <>
      {showStop && (
        <Modal onClose={() => setShowStop(false)} style={{ padding: '0px' }}>
          <div className="alert alert-dark" role="alert">
            {t('Trip_not_started')}
            <button
              type="button"
              className="btn btn-secondary btn-sm ms-2"
              onClick={() => setShowStop(false)}>
              {t('OK')}
            </button>
          </div>
        </Modal>
      )}
      {showWarningStop && (
        <Modal
          onClose={() => setShowWarningStop(false)}
          style={{ padding: '0px' }}>
          <div className="alert alert-dark" role="alert">
            You cannot start a trip before 7:00:00.
            <button
              type="button"
              className="btn btn-secondary btn-sm ms-2"
              onClick={() => setShowWarningStop(false)}>
              {t('OK')}
            </button>
          </div>
        </Modal>
      )}
      {/* {showWarning && (
        <Modal onClose={() => setShowWarning(false)} style={{ padding: '0px' }}>
          <div className="alert alert-info" role="alert">
            {t('Cannot_stop_tour')}
            <button
              type="button"
              className="btn btn-info btn-sm ms-2"
              onClick={() => setShowWarning(false)}>
              {t('OK')}
            </button>
          </div>
        </Modal>
      )} */}
      {showAnotherWarning && (
        <Modal
          onClose={() => setShowAnotherWarning(false)}
          style={{ padding: '0px' }}>
          <div className="alert alert-warning" role="alert">
            {t('tour_progress')}
            <button
              type="button"
              className="btn btn-warning btn-sm ms-2"
              onClick={() => setShowAnotherWarning(false)}>
              {t('OK')}
            </button>
          </div>
        </Modal>
      )}

      <div className="scrollMain">
        <div className="d-flex align-items-center justify-content-between flex-row mainHeader">
          <Sidebar
            isOpen={isSidebarOpen}
            onClose={() => setIsSidebarOpen(false)}
            isAdmin={isAdmin}
          />
          <FontAwesomeIcon
            icon={faBars}
            className="sidebar"
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            style={{ cursor: 'pointer' }}
          />
          <h1>{t('Dashboard')}</h1>

          <div
            ref={dropdownRef}
            className="position-relative d-flex align-items-center justify-content-center flex-column dropMain">
            {userProfile ? (
              <>
                <img
                  className="sidebar"
                  style={{ borderRadius: '50%', cursor: 'pointer' }}
                  src={userProfile}
                  alt="Profile"
                  width={40}
                  height={30}
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                />
                {isDropdownOpen && <Dropdown />}
              </>
            ) : (
              <>
                <FontAwesomeIcon
                  icon={faUserCircle}
                  className="sidebar"
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  style={{ cursor: 'pointer' }}
                />
                {isDropdownOpen && <Dropdown />}
              </>
            )}
          </div>
        </div>

        <div className="plueIcon trip-container mb-3" style={{ width: '100%' }}>
          <Calendar
            onDateChange={handleDateChange}
            selectedDate={selectedDate}
          />
        </div>
        <div className="d-flex align-items-center justify-content-center flex-column clock">
          {isLoading ? (
            <div style={{ textAlign: 'center', marginTop: '10%' }}>
              <Spinner />
            </div>
          ) : tripDataState.length === 0 ? (
            <h4
              style={{ textAlign: 'center', marginTop: '10%' }}
              className={'mb-2'}>
              {t('noData')}
            </h4>
          ) : (
            <>

              {tripDataState?.map((trip: TripDataState, index: number) => {
                return (<div className="main animation-item trip-card" key={trip.trip_id}>

                  <div
                    className="background-image"
                    style={{
                      backgroundImage: `url(${backgroundImages[index % backgroundImages.length]})`
                    }}
                  ></div>
                  <div className="details">

                    <h5 className="pt-2 d-flex align-items-center justify-content-center">
                      {(trip.status === 1 && <Clock tripId={trip.trip_id} startTime={trip.check_in_time} />)}
                    </h5>

                    <h4 className="pt-2">{`${t('Tour_#')}${" "}${moment(trip.check_in_time).format('DD.MM.YYYY')}`}</h4>

                    <div className="d-flex flex-wrap align-items-center justify-content-center garbage">
                      <h6
                        className="truncate-single-line"
                        style={{
                          color:
                            garbageTypeColors[trip.garbageType as keyof typeof garbageTypeColors] || 'black',
                          textAlign: 'center'
                        }}
                      >
                        {trip.garbageType}
                      </h6>
                    </div>

                    {trip.status === 3 || trip.status === 3 ? (
                      <div className=" d-flex align-items-center justify-content-center mt-5">
                        <Button
                          title={t('Complete')}
                          backgroundColor={'#17B169'}
                          color={'white'}
                          disableHover={true}
                          style={{ cursor: 'default' }}
                        />
                      </div>
                    ) : trip.status === 1 || trip.status === 1 ? (
                      <div className=" d-flex align-items-center justify-content-center flex-row clock mt-4">
                        <Button
                          title={t('pause')}
                          backgroundColor={'#029392'}
                          color={'white'}
                          width={'120px'}
                          onButtonClick={() => handleStopButtonClick(trip.trip_id, trip)}
                        />
                        <Button
                          title={t('end')}
                          backgroundColor={'#029392'}
                          color={'white'}
                          width={'120px'}
                          onButtonClick={() => {
                            localStorage.setItem("tripIdEnd", trip.trip_id.toString())
                            setTimeout(() => {
                              navigate('/endform');
                            }, 100);
                            setVechileType(trip.vehicleType);
                            if (tripDataState.length !== 0) {
                              tripDataState?.find((item: TripDataState) => {
                                if (item.trip_id === Number(tripIdEnd)) {
                                  setStartTime(item.startTime);
                                }
                              });
                            }
                            setTripIdEnd(trip.trip_id.toString());
                          }}
                        />
                      </div>
                    ) : trip.status === 2 || trip.status === 2 ? (
                      <div className="mt-5  d-flex align-items-center justify-content-center">
                        <Button
                          title={t('Resume')}
                          backgroundColor={'#027a79'}
                          color={'white'}
                          width={'160px'}
                          onButtonClick={() => handleResumeButtonClick(trip.trip_id)}
                        />
                      </div>
                    ) : (
                      <div className="mt-5  d-flex align-items-center justify-content-center">
                        <Button
                          title={
                            parseInt(isPaused) === trip.trip_id || trip.status === 2
                              ? t('Resume')
                              : t('start')
                          }
                          backgroundColor={'#027a79'}
                          color={'white'}
                          width={'160px'}
                          onButtonClick={() => handleStartButtonClick(trip.trip_id, trip)}
                        />
                      </div>
                    )}
                  </div>

                  <div className="right-section">
                    <div
                      className="yellow-ribbon"

                      style={{
                        backgroundColor:
                          trip.status === 1 ? 'orange' :
                            trip.status === 2 ? 'yellow' :
                              trip.status === 3 ? 'green' :
                                'gray'
                      }}

                    ></div>

                    <FontAwesomeIcon
                      icon={faInfo}
                      className="icon"
                      onClick={() =>
                        navigate('/tripDetails', {
                          state: { tripId: trip.trip_id }
                        })
                      }
                    />

                    {trip.status === 0 ? (
                      <FontAwesomeIcon
                        icon={faEdit}
                        className="icon"
                        onClick={() =>
                          navigate('/editTrip', {
                            state: { tripId: trip }
                          })
                        }
                      />
                    ) : null}

                    {trip.status === 3 || trip.status === 0 || trip.status === 2 ? (
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        className="icon "
                        onClick={() => {
                          handleDeleteUser(trip.trip_id);
                          setIsDelete(true);
                        }}
                      />
                    ) : null}
                  </div>
                </div>)
              }
              )}


            </>
          )}
          {!isLoading &&
            !isLoadingMore &&
            tripDataState?.length < totalTrips && (
              <div className="d-flex flex-column align-items-center justify-content-center mt-2 mb-3">
                {loader ?
                  <div style={{ textAlign: 'center' }}>
                    <Spinner />
                  </div> :
                  <Button
                    title={t('loadMore')}
                    onButtonClick={handleLoadMore}
                    backgroundColor="#026160"
                    color="white"
                  />}
              </div>
            )}
        </div>
        {isDelete && (
          <DeleteAlert
            message={t('delete_tour')}
            onClose={() => setIsDelete(false)}
            onDelete={handleConfirmDelete} imageUri={() => { }} breaks={[]} titile={t('Yes')} titileN={t('No')} />
        )}
        {isTourEnd && (
          <DeleteAlert
            istQuestion={vechileType}
            isTourEnded={true}
            handleChangeName={handleNameChange}
            PassengerName={PassengerName}
            mileage={isMileage}
            handleChange={handleMilageChange}
            fuel={fuel}
            perKm={perKm}
            dumped={dumpNumber}
            setDamagedPicture={selectedImage}
            imageUri={(path: string) => handleImageSelected(path)}
            handleDumpChange={handleChangeDumpNumber}
            handlePerKmChange={handleChangePerKm}
            handleFuelChange={handleChangeFuelQuantity}
            submit={() => handleEndButtonClick(tripIdEnd, vechileType)}
            onClose={handleCloseTourModal}
            errorDumped={errorDumped}
            errorPerKm={errorPerKm}
            errorFuel={errorFuel}
            errorName={errorName}
            errorMileage={errorMileage}
            emptyPerKm={errorPerKm || perKm === "" ? { backgroundColor: 'grey', color: 'white' }
              : { backgroundColor: '#027a79', color: 'white' }}
            emptySubmit={errorMileage || errorDumped || errorFuel || errorPerKm
              ? { backgroundColor: 'grey', color: 'white' }
              : { backgroundColor: '#027a79', color: 'white' }}
            emptyFuel={errorFuel || fuel === ''
              ? { backgroundColor: 'grey' }
              : { backgroundColor: '#027a79' }}

            emptyName={errorName || PassengerName === ''
              ? { backgroundColor: 'grey' }
              : { backgroundColor: '#027a79' }}
            emptyDump={errorDumped || dumpNumber === ''
              ? { backgroundColor: 'grey' }
              : { backgroundColor: '#027a79' }}
            emptyMileage={errorMileage || isMileage === ''
              ? { backgroundColor: 'grey' }
              : { backgroundColor: '#027a79' }}
            perKmDisabled={errorPerKm || perKm === '' ? true : false}
            submitDisabled={errorMileage || errorDumped || errorFuel ? true : false}
            dumpDisabled={errorDumped || dumpNumber === '' ? true : false}
            nameDisabled={errorName || PassengerName === '' ? true : false}
            fuelDisabled={errorFuel || fuel === '' ? true : false}
            mileageDisabled={errorMileage || isMileage === '' ? true : false}
            loading={loading}
            addBreak={addBreak}
            breaks={breaks}
            handlePauseTimeChange={(index: any, value: any) => handlePauseTimeChange(index, value, 'end_time')}
            handleEndTimeChange={(index: any, value: any) => handleEndTimeChange(index, value, 'start_time')}
            handleRemoveBreak={handleRemoveBreak} titile={''} titileN={''} />
        )}

        {isShowModal && (
          <DeleteAlert
            message={t('Tour_ended_successfully')}
            onClose={handleNO}
            onDelete={handleYes} imageUri={() => { }} breaks={[]} titile={t("new_task")} titileN={t("end_work")} />
        )}
      </div >
    </>
  )
}

export default DashBoard
