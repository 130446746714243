import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Carousel } from 'react-responsive-carousel';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import useFileUpload from '../hooks/useFileUpload';
import { AuthDetails } from '../libs/authDetails';
import { endPoints, getPasuseResumeTrip } from '../services/ApiServices';
import { TripDataState } from '../types/userList';
import DeleteAlert from './DeleteAlert';
import Header from './Header';
import StepDamageQuestion from './StepDamageQuestion';
import StepDieselQuestion from './StepDieselQuestion';
import StepPassengerName from './StepPassengerName';
import StepPauseTimes from './StepPauseTimes';
import StepSignature from './StepSignature';
import { getTripDetails } from '../services/ApiServices'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Modal from './Modal';
import DigitalSignature from './DigitalSignater';
import Button from './Button';
import Loader from 'react-js-loader';
import moment from 'moment';
import { TripResponse } from '../types/global';
interface PauseTime {
  end_time: string;
  start_time: string;
}

const EndStepperForm = (props: any) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [mileage, setMileage] = useState<any>('');
  const [breaks, setBreaks] = useState<PauseTime[]>([]);
  const [selectedImage, setSelectedImage] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [isload, setIsLoad] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const [yes, setYes] = useState(false);
  const [tripId, setTripId] = useState<any>('');
  const [tripDetails, setTripDetails] = useState<any>(null);
  const { UploadFile } = useFileUpload();
  const [startTime, setStartTime] = useState<string | null>('');
  const { token, is_admin, userId, username } = AuthDetails();
  const [perKm, setPerKm] = useState<string>('');
  const [fuel, setFuel] = useState<string>('');
  const [reFuel, setReFule] = useState<string>('')
  const [hours, setHours] = useState<string>('');
  const [dumpNumber, setDumpNumber] = useState<string>('');
  const [tripDataState, setTripData] = useState<TripDataState[]>([]);
  const [errorMileage, setErrorMileage] = useState('')
  const [errorDumped, setErrorDumped] = useState('')
  const [errorFuel, setErrorFuel] = useState('')
  const [errorReFuel, setErrorReFuel] = useState('')
  const [errorHours, setErrorHours] = useState('')
  const [errorPerKm, setErrorPerKm] = useState('')
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [signatureError, setSignatureError] = useState(false);
  const [signatureData, setSignatureData] = useState<string | null>(null);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [selectedDriver, setSelectedDriver] = useState<string>('');
  console.log("selectedDrivermm", selectedDriver)
  const [isModal, setIsModal] = useState(false)
  let tripIdEnd = localStorage.getItem("tripIdEnd")
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [pauseTimeGet, setPauseTimeGet] = useState<TripResponse[]>([]);
  const [startTimeErrors, setStartTimeErrors] = useState<any[]>(Array(breaks.length).fill(null));
  const [endTimeErrors, setEndTimeErrors] = useState<any[]>(Array(breaks.length).fill(null));
  useEffect(() => {
    localStorage.removeItem('currentStep');
    localStorage.removeItem('selectedDriver');
    setCurrentStep(1);
  }, []);

  const combinedBreaks = [...pauseTimeGet, ...breaks];
  console.log("breaksbreaks", breaks)
  console.log("pauseTimeGet", pauseTimeGet)
  const stepMapping: Record<string, number[]> = {
    'Driver': [1, 2, 3, 4, 5, 6, 7],
    'Loader': [ 5, 6, 7],
    'Machine Operator': [5, 6, 7],
    'Yard Worker': [7],
    'Workshop Worker': [7],
    'Locksmith': [7],
  };
  useEffect(() => {
    if (location.state && location.state.showModal) {
      setShowModal(true);
    }
  }, [location.state]);

  useEffect(() => {
    const storedStep = localStorage.getItem('currentStep');
    if (storedStep) {
      setCurrentStep(parseInt(storedStep, 10));
    } else {
      setCurrentStep(stepMapping[selectedDriver]?.[0] || 1);
    }
  }, [selectedDriver]);

  const handleNO = () => {
    setIsShowModal(false);
    navigate('/dashboard');
  };

  const handleYes = () => {
    setIsShowModal(false);
    setYes(true)
  };


  const handlePrevious = () => {
    setErrorDumped("");
    setErrorFuel("");
    setErrorReFuel("");
    setErrorMileage("");
    setErrorPerKm("");
    if (selectedDriver === 'Loader' && currentStep === 4) {
      navigate("/dashboard")
    } else if (selectedDriver === 'Machine Operator' && currentStep === 4) {
      navigate("/dashboard")
    }
    setCurrentStep(prevStep => {
      const prevStepIndex = stepMapping[selectedDriver]?.indexOf(prevStep) - 1;
      const nextStep = stepMapping[selectedDriver]?.[prevStepIndex] || 1;
      localStorage.setItem('currentStep', nextStep.toString());
      return nextStep;
    });
  };

  const handleNextHours = () => {

    if (hours === "") {
      setErrorHours(t('Hours_required'))

    } else if (!/^\d*$/.test(mileage)) {
      setErrorHours(t('Only_no_allowed'))
    }
    else {
      setErrorHours('')
      setCurrentStep((prevStep) => {
        const nextStep = prevStep + 1
        localStorage.setItem('currentStep', nextStep.toString())
        localStorage.setItem('selectedDriver', selectedDriver);
        return nextStep
      })
    }
  }
  const handleNextMileage = () => {
    if (mileage === "") {
      setErrorMileage(t('finale_required'))
    } else if (!/^\d*$/.test(mileage)) {
      setErrorMileage(t('Only_no_allowed'))
    } else if (tripDetails?.start_mileage !== null && Number(mileage) <= tripDetails?.start_mileage) {
      setErrorMileage(t('mileage_validation'))
    }
    else {
      setErrorMileage('')
      setCurrentStep((prevStep) => {
        const nextStep = prevStep + 1
        localStorage.setItem('currentStep', nextStep.toString())
        localStorage.setItem('selectedDriver', selectedDriver);
        return nextStep
      })
    }
  }
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {

    if (e.key === 'Enter') {
      e.preventDefault();
      if (mileage === "") {
        setErrorMileage(t('finale_required'))
      } else if (!/^\d*$/.test(mileage)) {
        setErrorMileage(t('Only_no_allowed'))
      } else if (tripDetails?.start_mileage !== null && Number(mileage) <= tripDetails?.start_mileage) {
        setErrorMileage(t('mileage_validation'))
      }
      else {
        setErrorMileage('')
        setCurrentStep((prevStep) => {
          const nextStep = prevStep + 1
          localStorage.setItem('currentStep', nextStep.toString())
          return nextStep
        })
      }
    }
  }
  const handleNextDump = () => {
    if (dumpNumber === "") {
      setErrorDumped(t('Dump_required'))
    } else if (!/^\d*$/.test(dumpNumber)) {
      setErrorDumped(t('Only_no_allowed'))
    } else {
      setErrorDumped('')
      setCurrentStep((prevStep) => {
        const nextStep = prevStep + 1
        localStorage.setItem('currentStep', nextStep.toString())
        return nextStep
      })
    }
  }

  const handleNextDiesel = () => {
    if (fuel === "") {
      setErrorFuel(t('Liter_required'))
    } else if (!/^\d*$/.test(fuel)) {
      setErrorFuel(t('Only_no_allowed'))
    } else {
      setErrorFuel('')
      setCurrentStep((prevStep) => {
        const nextStep = prevStep + 1
        localStorage.setItem('currentStep', nextStep.toString())
        return nextStep
      })
    }
  }
  const handleNext = () => {
    console.log("Start time errors:", startTimeErrors);
    console.log("End time errors:", endTimeErrors);

    const hasErrors = startTimeErrors.some(error => typeof error === 'string' && error.length > 0) ||
      endTimeErrors.some(error => typeof error === 'string' && error.length > 0);

    if (hasErrors) {
      alert("Please fix the errors before proceeding to the next step.");
      return;
    }
    setCurrentStep(prevStep => {
      const currentStepIndex = stepMapping[selectedDriver]?.indexOf(prevStep) || 0;
      const nextStep = stepMapping[selectedDriver]?.[currentStepIndex + 1] || prevStep;
      localStorage.setItem('currentStep', nextStep.toString());
      return nextStep;
    });
  };


  const handleNextReFuel = () => {
    if (reFuel === "") {
      setErrorReFuel(t('Fuel_required'))
    } else if (!/^\d*$/.test(fuel)) {
      setErrorReFuel(t('Only_no_allowed'))
    } else {
      setErrorReFuel('')
      setCurrentStep((prevStep) => {
        const nextStep = prevStep + 1
        localStorage.setItem('currentStep', nextStep.toString())
        return nextStep
      })
    }
  }

  const handleChangeFuelQuantity = (e: { target: { value: string } }) => {
    const { value } = e.target
    setFuel(value)
    if (!/^[0-9]+$/.test(value)) {
      setErrorFuel(t('Only_no_allowed'))
    } else {
      setErrorFuel('')
    }
  }
  const handleChangeRefuel = (e: { target: { value: string } }) => {
    const { value } = e.target
    setReFule(value)
    if (!/^[0-9]+$/.test(value)) {
      setErrorReFuel(t('Only_no_allowed'))
    } else {
      setErrorReFuel('')
    }
  }
  const handleChangeDumpNumber = (e: { target: { value: string } }) => {
    const { value } = e.target;
    setDumpNumber(value);
    if (!/^[0-9]+$/.test(value)) {
      setErrorDumped(t('Only_no_allowed'));
    } else {
      setErrorDumped('');
    }
  };
  const handleHoursChange = (e: { target: { value: string } }) => {
    const { value } = e.target
    setHours(e.target.value);
    if (!value) {
      setErrorHours(t('Hours_required'))
    } else if (!/^\d*$/.test(value)) {
      setErrorHours(t('Only_no_allowed'))
    }
    else {
      setErrorHours('')
    }
  }
  const handleMileageChange = (e: { target: { value: string } }) => {
    const { value } = e.target
    setMileage(e.target.value);
    if (!value) {
      setErrorMileage(t('finale_required'))
    } else if (!/^\d*$/.test(value)) {
      setErrorMileage(t('Only_no_allowed'))
    }
    else {
      setErrorMileage('')
    }
  }
  const handleGetPausedTrip = async () => {
    try {
      const response: TripResponse[] = await getPasuseResumeTrip(tripIdEnd, token);
      if (response && response.length > 0) {
        setPauseTimeGet(response.map(item => ({
          start_time: item.start_time,
          end_time: item.end_time || '',
          trip_id: item.trip_id
        })));
      }
    } catch (error) {
      console.error('Error fetching paused trip:', error);
    }
  };

  useEffect(() => {
    handleGetPausedTrip();
  }, [tripIdEnd]);

  const handlePauseTimeChange = (
    index: number,
    value: string,
    field: 'start_time' | 'end_time'
  ) => {
    let startTime, endTime;

    if (index < pauseTimeGet.length) {
      const updatedPauseTimes = [...pauseTimeGet];
      updatedPauseTimes[index] = { ...updatedPauseTimes[index], [field]: value };
      setPauseTimeGet(updatedPauseTimes);

      startTime = field === 'start_time' ? value : updatedPauseTimes[index].start_time;
      endTime = field === 'end_time' ? value : updatedPauseTimes[index].end_time;
    } else {
      const manualIndex = index - pauseTimeGet.length;
      const updatedBreaks = [...breaks];
      updatedBreaks[manualIndex] = { ...updatedBreaks[manualIndex], [field]: value };
      setBreaks(updatedBreaks);

      startTime = field === 'start_time' ? value : updatedBreaks[manualIndex].start_time;
      endTime = field === 'end_time' ? value : updatedBreaks[manualIndex].end_time;
    }

    validateTimes(startTime, endTime, index);
  };

  const validateTimes = (startTime: string, endTime: string, index: number) => {
    const newStartErrors = [...startTimeErrors];
    const newEndErrors = [...endTimeErrors];

    if (!startTime) {
      newStartErrors[index] = t("start_time_error");
    } else {
      newStartErrors[index] = null;
    }
    if (startTime && endTime && endTime <= startTime) {
      newStartErrors[index] = t("start_time_greater_end_time_error");
      newEndErrors[index] = t("end_time_end_time_error");
    } else {
      newEndErrors[index] = null;
    }

    const parsedStartTime = new Date(`1970-01-01T${startTime}`);
    const parsedEndTime = new Date(`1970-01-01T${endTime}`);

    for (let i = 0; i < combinedBreaks.length; i++) {
      if (i !== index) {
        const existingStartTime = new Date(`1970-01-01T${combinedBreaks[i].start_time}`);
        const existingEndTime = new Date(`1970-01-01T${combinedBreaks[i].end_time}`);

        if (existingStartTime && existingEndTime) {
          const overlaps =
            (parsedStartTime >= existingStartTime && parsedStartTime < existingEndTime) ||
            (parsedEndTime > existingStartTime && parsedEndTime <= existingEndTime) ||
            (parsedStartTime <= existingStartTime && parsedEndTime >= existingEndTime);

          const adjacentTimes =
            parsedEndTime.getTime() === existingStartTime.getTime() ||
            parsedStartTime.getTime() === existingEndTime.getTime();

          if (overlaps && !adjacentTimes) {
            newStartErrors[index] = t("time_overlap_error");
            newEndErrors[index] = t("time_overlap_error");
            break;
          }
        }
      }
    }

    setStartTimeErrors(newStartErrors);
    setEndTimeErrors(newEndErrors);
  };

  const handleEndTimeChange = (index: number, value: string) => {
    handlePauseTimeChange(index, value, 'end_time');
  };
  const handleRemoveBreak = (index: number) => {
    if (index < pauseTimeGet.length) {
      const updatedPauseTimes = pauseTimeGet.filter((_, i) => i !== index);
      setPauseTimeGet(updatedPauseTimes);
    } else {
      const manualIndex = index - pauseTimeGet.length;
      const updatedBreaks = breaks.filter((_, i) => i !== manualIndex);
      setBreaks(updatedBreaks);
    }
    const updatedStartErrors = startTimeErrors.filter((_, i) => i !== index);
    const updatedEndErrors = endTimeErrors.filter((_, i) => i !== index);

    setStartTimeErrors(updatedStartErrors);
    setEndTimeErrors(updatedEndErrors);
  };
  const addBreak = () => {
    const newBreak = { start_time: '', end_time: '' };
    setBreaks([...breaks, newBreak]);
  };

  const handleImageSelected = (imageUri: string) => {
    setSelectedImage(imageUri);
  };

  const handleSubmit = async (tripIdEnd: any) => {
    const tripIdNumber = tripIdEnd;
    setTripId(tripIdNumber)
    if (startTime !== null) {
      setIsLoading(true);
      const currentDateTime = new Date();
      const formattedDateTime = moment(currentDateTime).format("YYYY-MM-DD HH:mm:ss")
      let uploadFile = selectedImage as unknown as Blob | File;
      if (typeof selectedImage === 'string') {
        try {
          const response = await fetch(selectedImage);
          const blob = await response.blob();
          uploadFile = blob;
        } catch (error) {
          console.error('Error fetching image:', error);
          setIsLoading(false);
          return;
        }
      }
      try {
        const response = await endPoints(
          tripIdNumber,
          selectedImage ? '1' : '0',
          dumpNumber,
          mileage,
          '3',
          fuel,
          selectedImage || "",
          "",
          fuel,
          reFuel,
          JSON.stringify(breaks),
          formattedDateTime,
          token
        );
        if (response && response.isSuccessful) {
          setTripData(prevTripData =>
            prevTripData.map(trip =>
              trip.trip_id === tripIdNumber
                ? {
                  ...trip,
                  status: 3,
                  startTime: startTime,
                  formattedDateTime: formattedDateTime
                }
                : trip
            )
          );
          setIsShowModal(true);
          setIsModal(false)
        } else {
          console.error('Error in API response');
        }
      } catch (error) {
        console.error('Error ending tour:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };
  useEffect(() => {
    setIsLoad(true)
    if (tripIdEnd) {
      fetchTripDetails(tripIdEnd)

    }
  }, [tripIdEnd])
  let Signatue_image: any = localStorage.getItem("signatureImage")
  const fetchTripDetails = async (tripId: string) => {
    try {
      const response = await getTripDetails(tripId, token)
      console.log("responseresponseresponse",response)
      setTripDetails(response)
      setSelectedDriver(response?.category);

      localStorage.setItem('selectedDriver', response?.category)
      setIsLoad(false);
    } catch (error) {
      console.error('Error fetching trip details:', error)
    }
  }
  const handleSignatureSaved = (signatureDataURL: string | null) => {

    if (signatureDataURL === '') {
      setSignatureError(true);
    } else {
      setSignatureError(false);
      setSignatureData(signatureDataURL);
      if (selectedDriver === 'Machine Operator' || mileage.trim() !== "" && dumpNumber.trim() !== "") {
        setButtonDisabled(false)
      } else if (selectedDriver === 'Driver' && mileage.trim() !== "" || fuel.trim() !== "" && perKm.trim() !== "" && dumpNumber.trim() !== "") {
        setButtonDisabled(false)
      } else if (selectedDriver === 'Loader') {
        setButtonDisabled(false)
      } else {

      }
      setButtonDisabled(false)
    }
  };

  const handleComponentChange = (index: number) => {
    const adjustedIndex = index + 1;
    if (selectedDriver === 'Loader' && adjustedIndex === 5) {
      setCurrentStep(5);
    } else if (selectedDriver === 'Machine Operator' && adjustedIndex === 2) {
      setCurrentStep(5);
    } else {
      setCurrentStep(adjustedIndex);
    }

  };

  if (yes) {
    return (
      <Navigate
        to="/form"
        state={{ showModal: true }}
        replace={true}
      />
    )
  }
  return (
    <>

      <Header title={t("End_Tour")} />

      {isload ?
        <div className="d-flex align-items-center justify-content-center clock"
          style={{ height: "100%", backdropFilter: "blur(1px)" }}>
          <Loader
            type="bubble-top"
            bgColor={'#027a79'}
            color={'#027a79'}
            size={60}
          /></div> : null}
      {!isload &&
        <> <div className='stepcount'>
          <span style={{ fontSize: "18px", fontWeight: "bold", color: "#EC5800", marginTop: "7px" }}>
            {t("Step")}{" "}
            {selectedDriver === "Loader"
              ? `${currentStep === 5 ? `1` : currentStep === 6 ? `2` : currentStep === 7 ? `3` : `3`}/3`
              : selectedDriver === "Machine Operator"
                ? `${currentStep === 5 ? `1` : currentStep === 6 ? '2' : currentStep === 7 ? '3' :  currentStep}/3`
                : selectedDriver === "Yard Worker" || selectedDriver === "Locksmith" || selectedDriver === "Workshop Worker"
                  ? `${currentStep === 4 ? '1' : '1'}/1`
                  : `${currentStep}/7`}
          </span>
        </div>
          <Carousel
            showStatus={false}
            className="carousel"
            preventMovementUntilSwipeScrollTolerance={true}
            swipeable={false}
            showIndicators={false}
            renderArrowPrev={() => null}
            renderArrowNext={() => null}
            showThumbs={false}
            verticalSwipe="standard"
            dynamicHeight={false}
            selectedItem={currentStep - 1}
            onChange={handleComponentChange}
          >
            <>
              {currentStep === 1 && (selectedDriver === 'Driver') && (
                <StepPassengerName
                  isOperatingHours={false}
                  handleChangeHours={handleHoursChange}
                  operatingHours={hours}
                  selectedDriver={selectedDriver}
                  onNextHours={handleNextHours}
                  errorOperating={errorHours}
                  handleKeyPress={handleKeyPress}
                  onNext={handleNextMileage}
                  nameDisabled={props.nameDisabled}
                  mileage={mileage}
                  handleChange={handleMileageChange}
                  errorMileage={errorMileage}
                  onPrevious={handlePrevious} />
              )}
            </>
            <>
              {currentStep === 2 && (selectedDriver === 'Driver') && (
                <StepPauseTimes
                  breaks={[]}
                  handleDumpChange={handleChangeDumpNumber}
                  dumped={dumpNumber}
                  handleRemoveBreak={() => { }}
                  addBreak={() => { }}
                  onNext={handleNextDump}
                  onPrevious={handlePrevious}
                  handlePauseTimeChange={() => { }}
                  handleEndTimeChange={() => { }}
                  isDumped={true}
                  errorDumped={errorDumped}
                />
              )}
            </>
            {/* <>
          {currentStep === 3 && ((selectedDriver === 'Driver' )) && (
            <StepDieselQuestion
              // fuel={perKm}
              handleFuelChange={() => { }}
              errorFuelPerKm={errorPerKm}
              onNext={handleNextFuel}
              fuelDisabled={props.fuelDisabled}
              fuelPerKm={perKm}
              handleFuelPerKmChange={handleChangePerKm}
              isFuel={false}
              onPrevious={handlePrevious} fuel={''} />
          )}
        </> */}
            <>
              {currentStep === 3 && ((selectedDriver === 'Driver')) && (
                <StepDieselQuestion
                  fuel={fuel}
                  handleFuelChange={handleChangeFuelQuantity}
                  errorFuel={errorFuel}
                  onNext={handleNextDiesel}
                  fuelDisabled={props.fuelDisabled}
                  fuelPerKm={props.fuelPerKm}
                  handleFuelPerKmChange={() => { }}
                  isFuel={true}
                  onPrevious={handlePrevious}
                />)}
            </>
            <>
              {currentStep === 4 && (selectedDriver === 'Driver' ) && (
                <StepDieselQuestion
                  fuel={''}
                  handleFuelChange={() => { }}
                  errorFuel={''}
                  errorFuelPerKm={errorReFuel}
                  onNext={handleNextReFuel}
                  fuelDisabled={props.fuelDisabled}
                  fuelPerKm={reFuel}
                  handleFuelPerKmChange={handleChangeRefuel}
                  isFuel={false}
                  onPrevious={handlePrevious}
                />)}
            </>
            <>
              {currentStep === 5 && (selectedDriver === 'Driver' || selectedDriver === 'Loader' || selectedDriver === 'Machine Operator') && (
                <StepPauseTimes
                  breaks={combinedBreaks}
                  totalSelected={combinedBreaks.length}
                  handlePauseTimeChange={handlePauseTimeChange}
                  handleEndTimeChange={handleEndTimeChange}
                  handleRemoveBreak={handleRemoveBreak}
                  addBreak={addBreak}
                  onNext={handleNext}
                  startTimeErrors={startTimeErrors}
                  endTimeErrors={endTimeErrors}
                  onPrevious={handlePrevious}
                  isDumped={false} />)}
            </>

            <>
              {currentStep === 6 && ((selectedDriver === 'Driver' || selectedDriver === 'Loader' || selectedDriver === 'Machine Operator')) && (
                <StepDamageQuestion
                  setDamagedPicture={handleImageSelected}
                  imageUri={handleImageSelected}
                  onNext={handleNext}
                />)}
            </>
            <>
              {currentStep === 7 && ((selectedDriver === 'Driver' || selectedDriver === 'Loader' || selectedDriver === 'Machine Operator' || selectedDriver === 'Yard Worker' || selectedDriver === 'Workshop Worker' || selectedDriver === 'Locksmith')) && (
                <StepSignature
                  onClick={() => setIsModal(true)}
                />)}

            </>
          </Carousel></>}
      {isModal &&
        <>
          <Modal onClose={() => setIsModal(false)} contentWidth={'30%'}>
            {Signatue_image === null && <div className="d-flex align-items-center justify-content-between">
              <h6>{t('Digital_Signature')}</h6>
              <FontAwesomeIcon
                icon={faTimes}
                className="crossIcon"
                color="#027a79"
                onClick={() => setIsModal(false)}
              />
            </div>}
            <DigitalSignature
              onclick={() => setIsModal(false)}
              selectedDriver={selectedDriver}
              onSignatureSaved={handleSignatureSaved}
              setValidationError={setSignatureError}
              validationError={signatureError}
            />
            <div className="d-flex align-items-center justify-content-center mb-1 mt-3 clock">
              {isLoading ? (
                <Loader
                  type="bubble-top"
                  bgColor={'#027a79'}
                  color={'#027a79'}
                  size={30}
                />
              ) : (
                <Button
                  onButtonClick={() => handleSubmit(tripIdEnd)}
                  title={t('Submit')}
                  // backgroundColor={submitDisabled?"":"#027a79"}
                  color="white"
                  style={
                    buttonDisabled
                      ? {
                        pointerEvents: 'none',
                        opacity: 0.5,
                        background: 'grey'
                      }
                      : {
                        backgroundColor: '#EC5800'
                      }
                  }
                  disabled={buttonDisabled}
                />
              )}
            </div>
          </Modal>
        </>
      }

      {isShowModal && (
        <DeleteAlert
          message={t('Tour_ended_successfully')}
          onClose={handleNO}
          onDelete={handleYes}
          imageUri={() => { }}
          breaks={[]} titile={t("new_task")} titileN={t("end_work")} />
      )}
    </>
  );
};

export default EndStepperForm;
