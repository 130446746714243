import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from './Button';
import { faChevronCircleLeft, faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface StepDieselQuestionProps {
  fuel: string;
  handleFuelChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errorFuel?: string;
  onNext?: () => void;
  fuelDisabled?: string | boolean | undefined;
  fuelPerKm: string;
  handleFuelPerKmChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errorFuelPerKm?: string;
  isDisabled?: boolean;
  isFuel: boolean;
  onPrevious?: any
}

const StepDieselQuestion: React.FC<StepDieselQuestionProps> = ({
  fuel,
  handleFuelChange,
  errorFuel,
  onNext,
  fuelDisabled,
  fuelPerKm,
  handleFuelPerKmChange,
  errorFuelPerKm,
  isDisabled,
  isFuel,
  onPrevious
}) => {
  const { t } = useTranslation();
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (onNext) {
        onNext();
      }
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };
  return (
    <>
      <div className="d-flex align-items-center justify-content-center m-auto height">
        <form className="main-container" onSubmit={handleSubmit}>
          <div className="userHeading" style={{ background: "#EC5800" }}>
            <h2 className="textcolor">
              {isFuel ? t('diesel_hed') : t('FuelKilometers')}
            </h2>
          </div>
          <div className='p-3'>
            {isFuel ? (<div>
              {/* <h6 className="">{t('diesel_hed')}</h6> */}
              <div className="d-flex flex-column mt-2p">
                <input
                  type="text"
                  placeholder={t('Diesel_amount')}
                  value={fuel}
                  onChange={handleFuelChange}
                  className="inputform"
                  onKeyDown={handleKeyPress}
                />
                {errorFuel && <span className="error-message">{errorFuel}</span>}

              </div>
            </div>
            ) : (
              <div>
                {/* <h6 className="">{t("FuelKilometers")}</h6> */}
                <div className="d-flex  flex-row w-100 mt-3">
                  <input
                    type="text"
                    placeholder={t('FuelKilometers')}
                    value={fuelPerKm}
                    onChange={handleFuelPerKmChange}
                    className="inputform w-100"
                    // style={{ width: '75%' }}
                    onKeyDown={handleKeyPress}
                  />
                  {/* <p className="m-2"> l/100km</p> */}

                </div>
                {errorFuelPerKm && <span className="error-message">{errorFuelPerKm}</span>}

              </div>
            )}
          
            <div
              className="d-flex align-items-center justify-content-center  mb-3 mt-4" style={{ gap: 50 }}>
              <div className="d-flex justify-content-center flex-row  align-items-center gap-1" >
                <h6 className="" onClick={() => onPrevious()} style={{ cursor: 'pointer', color: '#EC5800', fontSize: '20px' }} >
                  {t("back")}
                </h6>
                <FontAwesomeIcon
                  icon={faChevronCircleLeft}
                  color="#EC5800"
                  className=" backIcon"
                  onClick={() => onPrevious()}
                />
              </div>

              <div className="d-flex justify-content-center flex-row  align-items-center gap-1" >
                <FontAwesomeIcon
                  icon={faChevronCircleRight}
                  color="#EC5800"
                  className="backIcon"
                  onClick={onNext}
                />
                <h6 className="" onClick={onNext} style={{ cursor: 'pointer', color: '#EC5800', fontSize: '20px' }} >
                  {t("further")}
                </h6>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default StepDieselQuestion;
